@import '~@angular/material/theming';
@import '../../../../themes/colors.scss';

@mixin login-theme($theme) {
  $background: map-get($theme, background);
  $background-color: mat-color($background, card);

  .login {
    width: 600px;
    &.mat-card {
      padding: 32px;
    }
    .mat-card-header-text {
      width: 100%;
      text-align: center;
      .mat-card-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -50px;
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        letter-spacing: -1px;
        .back-button {
          margin-right: 10px;
        }
      }
      .mat-card-subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        .lock {
          margin-right: 5px;
        }
      }
    }
    .mat-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .textbox {
        width: 346px;
        .icon {
          cursor: pointer;
        }
        &.spacer {
          margin-top: 15px;
        }
      }
      .sign-in {
        width: 346px;
        margin-top: 15px;
        background-color: #007A5A;
        color: $background-color;
      }
      .container {
        width: 346px;
        display: flex;
        flex-direction: column;
        .checkbox {
          margin-top: 15px;
        }
        .link {
          width: 110px;
          font-size: 12px;
          margin-top: 15px;
        }
      }
    }
  }
  .join {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .link {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
