@import '~@angular/material/theming';

@mixin footer-theme($theme) {
  $background: map-get($theme, background);
  $background-color: mat-color($background, card);

  .footer {
    background-color: $background-color;
    @include mat-elevation(2, #5C5C5C, 0.8);
    height: 165px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    .mat-toolbar-row {
      height: 165px;
      width: 900px;
      text-align: left;
      align-items: flex-start;
    }
    .column {
      padding-left: 100px;
      width: 300px;
      .links {
        display: flex;
        flex-direction: column;
        .link {
          width: 25%;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          color: #616061;
          line-height: 24px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
