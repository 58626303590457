@import '~@angular/material/theming';
@import '../../../../themes/colors.scss';

@mixin workspace-theme($theme) {
  $background: map-get($theme, background);
  $background-color: mat-color($background, card);

  .workspace {
    width: 600px;
    &.mat-card {
      padding: 32px;
    }
    .mat-card-header-text {
      width: 100%;
      text-align: center;
      .mat-card-title {
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        letter-spacing: -1px;
      }
      .mat-card-subtitle {
        padding-top: 15px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }
    .mat-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .url {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -10px;
        .lock {
          padding: 6px;
        }
        .domain {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
        .name {
          padding: 6px;
          position: relative;
          top: 2px;
        }
      }
      .continue {
        width: 346px;
        color: $background-color;
      }
      .find {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .link {
          margin-top: 15px;
          font-size: 12px;
        }
      }
    }
  }
  .create {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .link {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
