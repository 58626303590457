@import '~@angular/material/theming';

@mixin header-theme($theme) {
  $background: map-get($theme, background);
  $background-color: mat-color($background, card);
  $theme-primary: map-get($theme, primary);
  $primary: mat-color($theme-primary);
  $primary-50: mat-color($theme-primary, lighter, 0.5);

  .header {
    background-color: $background-color;
    @include mat-elevation(1, #5C5C5C, 0.8);
    padding: 0 20px;
    .links {
      flex-grow: 1;
      text-align: right;
      .link {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #616061;
        padding: 0 20px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .register {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: $primary;
        background-color: $primary-50;
      }
    }
  }
}
