@import '~@angular/material/theming';
@import '../../../../themes/colors.scss';

@mixin forgot-password-theme($theme) {
  $background: map-get($theme, background);
  $background-color: mat-color($background, card);

  .forgot-password {
    width: 600px;
    &.mat-card {
      padding: 32px;
    }
    .mat-card-header-text {
      width: 100%;
      text-align: center;
      .mat-card-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -5px;
        font-weight: 500;
        font-size: 32px;
        line-height: 37px;
        letter-spacing: -1px;
        .success {
          margin-right: 10px;
        }
      }
      .mat-card-subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        .sub-title {
          width: 310px;
        }
      }
    }
    .mat-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .domain {
        display: flex;
        align-items: center;
        justify-content: center;
        .lock {
          margin-right: 5px;
        }
      }
      .textbox {
        margin-top: 10px;
        width: 346px;
        .icon {
          cursor: pointer;
        }
        &.spacer {
          margin-top: 15px;
        }
      }
      .reset {
        width: 346px;
        margin-top: 15px;
        background-color: #007A5A;
        color: $background-color;
      }
      .message {
        margin-top: 15px;
        width: 346px;
        text-align: center;
      }
    }
  }

}
